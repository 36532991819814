import { AnimatePresence } from 'framer-motion';
// import { lazy, Suspense } from 'react';
import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
// import AppFooter from './components/shared/AppFooter';
import AppHeader from './components/shared/AppHeader';
import './css/App.css';
import UseScrollToTop from './hooks/useScrollToTop';
import Service from './pages/service/Service';
import About from './pages/AboutMe.jsx';
import Projects from './pages/Projects.jsx';
// import Home from './pages/Home.jsx';
// import ProjectSingle from './pages/ProjectSingle.jsx';
import { Helmet } from 'react-helmet';
// import SplashLogo from './components/splashscreen/SplashLogo.js';
// import WhirlAnimation from './components/splashscreen/whirl-animation.js';
// const About = lazy(() => import('./pages/AboutMe'));
// const Contact = lazy(() => import('./pages/Contact.jsx'));
// import TawkTo from 'tawkto-react'
import Footer2 from './components/footernew/Footer.jsx';
import Home2 from './pages/Home2.jsx';
// import Home2 from './components/pricing/Pricing.jsx';
// import Home2 from './components/solusi/Solusi.jsx';
import Blog from './pages/blog/Blog.jsx';
import BlogDetail from './pages/blog/DetailBlog.jsx';
// import CounterItem from './components/about/CounterItem.jsx';
import withSplashScreen from './components/splashscreen/SplashFIx.js'
import { UserAuthContextProvider } from "./auth/UserAuthContext.js";
// import Home3 from './auth/Home'
import ProtectedRoute from "./auth/ProtectedRoute.js";
// import Home5 from './auth/home5.jsx';
import Login from './auth/Login.jsx';
import Signup from './auth/Signup.jsx';
import News from './auth/News.jsx';
import Prof from './auth/Profile.jsx';
import UpdateProfil from './auth/UpdateProfil.jsx';
import BeritaList from './auth/dashboard/BeritaList.jsx';
import BeritaCard from './auth/berita/BeritaCard.jsx';
import BeritaDetail from './auth/berita/BeritaDetail.jsx';
import CardServices from './pages/service/CardServices.jsx';
// import ContactDetails from './components/contact/ContactDetails.jsx';
// import BackVid from './components/heronew/BackVid.jsx';
import Kontak from './components/kontak/Kontak.jsx';
import PortoParall from './components/porto/PortoParall.jsx';
import Home5 from './auth/home5.jsx';
import BackVid from './components/heronew/BackVid.jsx';
import ReactWhatsappButton from 'react-whatsapp-button';

// import Homee from './auth/Home.js'
function App() {
	return (
		<div className="bg-secondary-light dark:bg-primary-dark" id='bgutama'>
			{/* <FloatingWhatsApp /> */}
			<Helmet>
				<title>PrimeTime | Digital Solution</title>
				<meta property="og:title" content="PrimeTime Digital" />
				<meta name="description" content="PrimeTime Digital - Menyediakan solusi digital terbaik untuk bisnis Anda." />
				<meta name="keywords" content="PrimeTime Digital, Digital Agency, solusi digital, teknologi, marketing, malang" />
				<meta name="author" content="PrimeTime Digital Malang" />
				<meta name="robots" content="index, follow" />

			</Helmet>
			<UserAuthContextProvider>
				<Router>
					<ScrollToTop />
					<AppHeader />
					<Routes>
						<Route path="/" element={<Home2 />} />
						<Route path="projects" element={<Projects />} />
						{/* <Route path="projects/single-project" element={<ProjectSingle />} /> */}
						{/* <Route path="service" element={<Service />} /> */}
						<Route path="service" element={<CardServices />} />
						<Route path="about" element={<About />} />
						<Route path="blog" exact element={<Blog />} />
						<Route path="login" exact element={<Login />} />
						<Route path="signup" exact element={<Signup />} />
						<Route path="news" exact element={<News />} />
						<Route path="prof" exact element={<Prof />} />
						<Route path="beritalist" exact element={<BeritaList />} />
						<Route path="profupdt" exact element={<UpdateProfil />} />
						<Route path="/blog/:id" element={<BlogDetail />} />
						{/* <Route path="/kontak2" element={<ContactDetails />} /> */}
						<Route path="/kontak" element={<Kontak />} />
						<Route path="/konta2" element={<Kontak />} />
						<Route path="/berita" element={<BeritaCard />} />
						<Route path="/berita/:beritaId" element={<BeritaDetail />} />
						<Route path="/herovidbg" element={<BackVid />} />
						{/* <Route path="/tesfeatt" element={<Home5 />} /> */}
						<Route
							path="/tesss"
							element={
								<ProtectedRoute>
									<Home5 />
								</ProtectedRoute>
							}
						/>
					</Routes>
					<Footer2 />
				</Router>
			</UserAuthContextProvider>
			<ReactWhatsappButton
				countryCode="62"
				phoneNumber="8812323888"
				animated
			/>
			<UseScrollToTop />
		</div>

	);
}

export default withSplashScreen(App);
