import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Helmet } from 'react-helmet';

const BeritaCard = () => {
  const [beritaList, setBeritaList] = useState([]);

  useEffect(() => {
    const fetchBerita = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "berita"));
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setBeritaList(newData);
      } catch (error) {
        console.error("Error fetching documents: ", error);
      }
    };

    fetchBerita();
  }, []);

  const handleDelete = async (beritaId) => {
    try {
      await deleteDoc(doc(db, "berita", beritaId));
      // Refresh the beritaList after deletion
      const querySnapshot = await getDocs(collection(db, "berita"));
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setBeritaList(newData);
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  return (
    <div className="bungkusss" style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
      <Helmet>
					<title>PrimeTime | Blog </title>
					<meta property="og:title" content="PrimeTime Digital" />
					<meta name="description" content="PrimeTime Digital - Menyediakan solusi digital terbaik untuk bisnis Anda." />
					<meta name="keywords" content="berita" />
					<meta name="author" content="PrimeTime Digital Malang" />
					<meta name="robots" content="index, follow" />
				</Helmet>
      <div className="bungkss" style={{ width: '90%', margin: '5px', fontFamily: 'Arial, sans-serif', overflowX: 'auto', scrollbarColor: '#feefee #f2f3f4' }}>
        <h2 style={{ color: '#333', fontSize: '24px' }}>Seluruh Berita</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {beritaList.map((berita) => (
            <Link
              key={berita.id}
              to={`/berita/${berita.id}`} // Pass judul as a parameter
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <div style={{ margin: '10px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px', width: '300px', textAlign: 'center', cursor: 'pointer' }}>
                <h3 style={{ color: '#333' }}>{berita.judul}</h3>
                <img src={berita.image} alt={berita.altimg} style={{ maxWidth: '100%', maxHeight: '150px', objectFit: 'cover', borderRadius: '5px', marginBottom: '10px' }} />
                <p style={{ color: '#666' }}>{berita.frasaKunci}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BeritaCard;
