function AppFooterCopyright() {
	return (
		<div className="text-sm text-ternary-dark flex justify-center items-center text-center">
			<div className="text-ternary-light" style={{ cursor: 'pointer'  }}>
				
				<a
					href="/"
					target="__blank"
					className="textcopyrig"
				>
					 &copy; Copyright {new Date().getFullYear()}
					&nbsp; Primetime - All rights reserved. 
				</a>
				.
				{/* <a
					href="https://primetimedigital.id"
					target="__blank"
					className="textcopyrig"
				>
					Primetime
				</a> */}
			</div>
		</div>
	);
}

export default AppFooterCopyright;
