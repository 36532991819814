import React from "react";
import Marquee from "react-fast-marquee";
import { skillsData } from "../../../data/slider/DataPartUS";
import { skillsImage } from "../../../data/slider/list";
const AboutSlider = () => {
  return (
    <div className="skillsContainer">
      <p className="font-general-medium text-2xl sm:text-4xl mt-14 text-ternary-dark dark:text-ternary-light text-center">
        Some of the brands that trust Us
      </p>
      <div className="skill--scroll">
        <Marquee
          gradient={false}
          speed={100}
          pauseOnClick={true}
          delay={0}
          play={true}
          direction="right"
        >
          {skillsData.map((skill, id) => (
            <div className="skill--box" key={id}>
              <img
                className="skill-image"
                src={skillsImage(skill)}
                alt={skill}
              />
              {/* <p>{skill}</p> */}
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default AboutSlider;
