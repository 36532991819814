import React from "react";
import "./Blog.css";
import { Link } from "react-router-dom";
import { Blogs } from "../../data/data-blog";


const Blogss = ({ id, imageMain, altImg, judul, judulSEO,descMeta, date }) => {
  return (
    <div className="newscard shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark">
      <Link to={`/blog/${id}`}>
        <img src={imageMain} alt={altImg} />
        <div className="newss text-left px-4 py-6">
          <p className="jdulseo font-general-medium text-lg md:text-xl text-ternary-dark dark:text-ternary-light mb-2">
            {judulSEO}
          </p>
          <p className="descmeta dark:text-ternary-light">{descMeta}</p>
        </div>
      </Link>
        <hr />
        <p className="fut dark:text-ternary-light" style={{ textAlign: 'end', marginRight:'5px'}}>{date}</p>
    </div>
  );
};

const Blog = () => {
    return (
      <div className="flex" style={{ flexDirection: "column" }}>
        <div className="service-page">
          <p className="font-general-medium text-2xl sm:text-4xl mb-12 text-ternary-dark dark:text-ternary-light">
            Blog
          </p>
          <p>Kategori</p>
          <div className="services">
            {Blogs.map((service, index) => (
              <Blogss key={index} {...service} />
            ))}
          </div>
        </div>
        </div>
      );
}

export default Blog;
