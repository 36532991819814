// Import images
// import WebImage1 from '../images/web-project-1.jpg';
// import WebImage2 from '../images/web-project-2.jpg';
// import MobileImage1 from '../images/mobile-project-1.jpg';
// import MobileImage2 from '../images/mobile-project-2.jpg';
// import UIImage1 from '../images/ui-project-1.jpg';
// import UIImage2 from '../images/ui-project-2.jpg';
import starpro from '../images/porto/starpro.jpg';
import bhasil from '../images/porto/bag-hasil.jpg';
import miakr from '../images/porto/miakrawu.jpg';
import calyna from '../images/porto/img-product/calyna-candy.jpg';
import shamila from '../images/porto/img-product/shamila.jpg';
import ghufrana from '../images/porto/img-product/ghufrana.jpg';

export const projectsData = [
	{
		id: 1,
		title: 'Bagi_Hasil',
		// title: 'STARPROPERTI',
		category: 'SMM',
		img: bhasil,
		ProjectHeader: {
			title: 'Project Management UI - From Context',
			publishDate: 'Jul 26, 2021',
			tags: 'UI / Frontend',
		},
	},
	{
		id: 2,
		title: 'STARPROPERTI',
		// title: 'Bagi_Hasil',
		category: 'SMM',
		img: starpro,
	},
	{
		id: 3,
		title: 'MIAKRAWU',
		category: 'SMM',
		img: miakr,
	},
	{
		id: 4,
		title: 'CALYNA CANDY',
		category: 'PHOTO PRODUCT',
		img: calyna,
	},
	{
		id: 5,
		title: 'SHAMILA',
		category: 'PHOTO PRODUCT',
		img: shamila,
	},
	{
		id: 6,
		title: 'GHUFRANA',
		category: 'PHOTO PRODUCT',
		img: ghufrana,
	},
];
