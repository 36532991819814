import React from "react";
import AppBanner from "../components/shared/AppBanner";
// import Why from "./faq/Why";
// import AboutCounter from "../components/about/AboutCounter";
// import ServicePage2 from "./service/Service2";
// import AboutSlider from "../components/about/slider/AboutSlider";

// import Blog2 from "./blog/Blog2";
import Benefit from "./benefit/Benefit";
import Solusi from "../components/solusi/Solusi";
// import Testi from "../components/testi/Testi";
// import Clients from "../components/testi/TestimonialSlider";
import HeaderNew from "../components/heronew/HeroSec";
import Pricing from "../components/pricing/Pricing";
import ProjectCarousel from "../components/carousel/ProjectCarousel";
import ReviewCarousel from "../components/carousel/ReviewCarousel";
import BackVid from "../components/heronew/BackVid";
// import Whyy from "./faq/Whyy";

const Home2 = () => {
  return (
    <div className="flex" style={{ flexDirection: "column" }}>
      {/* <div className="container mx-auto">
        <AppBanner />
      </div> */}
      {/* <HeaderNew /> */}
      <BackVid />
      <Benefit />
      <Solusi />
      <ProjectCarousel />
      <ReviewCarousel />
      {/* <Solusi /> */}
      {/* <ServicePage2 /> */}
      {/* <AboutSlider /> */}
      {/* <Whyy /> */}
      {/* <Why /> */}
      {/* <Blog2 /> */}
      {/* <Clients /> */}
      {/* <Pricing /> */}
      {/* <Testi /> */}
    </div>
  );
};

export default Home2;
