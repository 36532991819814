import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from './firebase';
import { useParams } from 'react-router-dom';

const UpdateProfil = () => {
  const { uid } = useParams();
  const [user, loading, error] = useAuthState(auth);
  const [profile, setProfile] = useState(null);
  const [namaLengkap, setNamaLengkap] = useState('');
  const [image, setImage] = useState('');
  const [level, setLevel] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userDoc = await db.collection('users').doc(uid).get();
        setProfile(userDoc.data());
        setNamaLengkap(userDoc.data().namaLengkap);
        setImage(userDoc.data().image);
        setLevel(userDoc.data().level);
      } catch (error) {
        console.error('Error fetching profile: ', error);
      }
    };

    if (user) {
      fetchProfile();
    }
  }, [uid, user]);

  const handleUpdateProfile = async () => {
    try {
      await db.collection('users').doc(uid).update({
        namaLengkap: namaLengkap,
        image: image,
        level: level,
      });
      console.log('Profil berhasil diperbarui');
    } catch (error) {
      console.error('Error updating profile: ', error);
    }
  };

  return (
    <div className='flex justify-center' style={{flexDirection: 'column' ,height: '100vh', width: '100%', alignItems:'center'}}>
      <h2>Perbarui Profil</h2>
      {/* {loading && <p>Loading...</p>} */}
      {/* {error && <p>Error: {error.message}</p>} */}
      {profile && (
        <div>
          <form>
            <div>
              <label>Nama Lengkap:</label>
              <input type="text" value={namaLengkap} onChange={(e) => setNamaLengkap(e.target.value)} />
            </div>
            <div>
              <label>Link Image Profil:</label>
              <input type="text" value={image} onChange={(e) => setImage(e.target.value)} />
            </div>
            <div>
              <label>Level Akun:</label>
              <input type="text" value={level} onChange={(e) => setLevel(e.target.value)} />
            </div>
            <button type="button" onClick={handleUpdateProfile}>
              Perbarui Profil
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default UpdateProfil;
