import React, { useState, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import "./Projecttstyle.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import porto1 from '../../images/porto/porto-primetime-shamila.png';
import porto2 from '../../images/porto/porto-primetime-calyna.png';
import porto3 from '../../images/porto/porto-primetime-photoshoot.png';
import porto4 from '../../images/porto/porto-primetime-photoshoot2.png';
import porto5 from '../../images/porto/porto-primetime-photoshoot3.png';
import porto6 from '../../images/porto/porto-design-primetime.png';
import porto7 from '../../images/porto/porto-design-primetime2.png';
const projects = [
  {
    name: "Shamila",
    description: "Photo Product",
    imageUrl: porto1
      // "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjXuGaYxSTLZythunK_frqaqLkaoJq4RyW51U84zSg7Brpn8HUuIJnwdXL8SDBljqFEEHLec5O2lZKyHquLXkNas871MsOWUixDBb05iD-TmVJv60hb9MofqRfZzQD-Vcgh602sPQaBqsew6Yhq1MIQvoTErUZvaXDW9BWAiTn1N0IlW-mWUpi6uMSv/s1600/21250101_6384164.jpg",
    // imageUrl: 'https://machung.ac.id/wp-content/uploads/2021/12/3-5.jpg',
  },
  {
    name: "Calyna Candy",
    description: "Photo Product",
    imageUrl: porto2
      // "https://blog.sekolahdesain.id/wp-content/uploads/2023/05/Untitled-design-2023-05-05T102227.867.png",
  },
  {
    name: "Photo Shoot",
    description: "Photo Shoot",
    imageUrl: porto3
      // "https://blog.sekolahdesain.id/wp-content/uploads/2023/03/6411261-1024x683.jpg",
  },
  {
    name: "Photo Shoot",
    description: "Photo Shoot",
    imageUrl: porto4
      // "https://image.popbela.com/content-images/post/20220830/5c80349a10cf5043640f16d5453e789d.png?width=1600&format=webp&w=1600",
  },
  {
    name: "Rendra MS",
    description: "Photo Shoot",
    imageUrl: porto5
      // "https://storage.googleapis.com/finansialku_media/wordpress_media/2023/04/d0bbc454-4-contoh-portofolio-desain-grafis-3.webp",
  },
  {
    name: "Design",
    description: "Design",
    imageUrl: porto6
      // "https://blog.sekolahdesain.id/wp-content/uploads/2023/03/6411261-1024x683.jpg",
  },
  {
    name: "Design",
    description: "Design",
    imageUrl: porto7
      // "https://blog.sekolahdesain.id/wp-content/uploads/2023/03/6411261-1024x683.jpg",
  },
];

const ProjectCarousel = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    Autoplay({ delay: 11000 }),
  ]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (!emblaApi) return;
    const onSelect = () => {
      setSelectedIndex(emblaApi.selectedScrollSnap());
    };
    emblaApi.on("select", onSelect);
    return () => emblaApi.off("select", onSelect);
  }, [emblaApi]);

  const handlePrev = () => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  };

  const handleNext = () => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  };

  return (
    <div
      className="outerprooj flex dark:bg-ternary-dark"
      style={{
        padding: "0 35px",
        flexDirection: "column",
        width: "100%",
        minHeight: "70dvh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1 className="h1proj dark:text-ternary-light mb-8 mt-2">Recent&nbsp;</h1>
      {/* <h6 className="h6proj dark:text-ternary-light mb-4 mt-2">
        lorem ipsum dolor sit amet consectetur adipisicing elit.
      </h6> */}
      <div className="project-embla">
        <div className="project-embla__viewport" ref={emblaRef}>
          <div className="project-embla__container">
            {projects.map((project, index) => (
              <div
                key={index}
                className={`project-embla__slide ${
                  index === selectedIndex ? "project-embla__slide--active" : ""
                }`}
              >
                <div className="project-card">
                  <img
                    src={project.imageUrl}
                    alt={project.name}
                    className="project-card__image"
                  />
                  <div className="project-card__content">
                    <h3 className="project-card__name">{project.name}</h3>
                    <p className="project-card__description">
                      {project.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="project-embla__controls">
          <button className="project-embla__prev" onClick={handlePrev}>
            <IoIosArrowBack
              className="dark:text-ternary-light"
              color="#fff"
              size={23}
            />
          </button>
          <button className="project-embla__next" onClick={handleNext}>
            <IoIosArrowForward
              className="dark:text-ternary-light"
              color="#fff"
              size={23}
            />
          </button>
        </div>
      </div>
      {/* <video controls poster="../../images/porto/bag-hasil.jpg" style={{ paddingTop: '1rem' }}>
        <source src={pidio} type="video/mp4" />
      </video> */}
    </div>
  );
};

export default ProjectCarousel;
