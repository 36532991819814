// src/components/FAQPage.jsx
import React, { useState } from "react";
import "./FaqPage.css"; // File CSS untuk gaya halaman FAQ
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { faqData } from "../../data/faq";
// import { Helmet } from 'react-helmet';

const FAQItem = ({ index, question, answer, openQuestionIndex, setOpenQuestionIndex }) => {
  const isOpen = index === openQuestionIndex;

  const handleToggle = () => {
    setOpenQuestionIndex(isOpen ? null : index);
  };

  return (
    <div className="faq-item">
      <div className={`question ${isOpen ? "open" : ""}`} onClick={handleToggle}>
        <span className="question-text">{question}</span>
        {isOpen ? <MdExpandLess className="arrow-icon" /> : <MdExpandMore className="arrow-icon" />}
      </div>
      {isOpen && <div className="answer text-ternary-dark dark:text-ternary-light">{answer}</div>}
    </div>
  );
};

const FAQPage = () => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);

  return (
    <div className="faq-page">
      <p className="font-general-medium text-2xl sm:text-4xl mb-2 text-ternary-dark dark:text-ternary-light">
        Mengapa Harus Kami?
      </p>
      <p className="text-lg dark:text-ternary-light">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia neque culpa delectus</p>
      <div className="faq-list">
        {faqData.map((faq, index) => (
          <FAQItem
            key={index}
            index={index}
            openQuestionIndex={openQuestionIndex}
            setOpenQuestionIndex={setOpenQuestionIndex}
            {...faq}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQPage;
