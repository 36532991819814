// src/components/ServicePage.jsx
import React from "react";
import "./Solusi.css";

import { solusiData } from "../../data/solusi";
// import Counter from "../about/counterNew/Counter";
// import FAQPage from "../faq/FaqPage";
const Slsi = ({ image, altImg, desc, solusi, bgImg }) => {
  return (
    <div
      className="solusi shadow-sm hover:shadow-xl cursor-pointer dark:bg-ternary-dark dark:shadow-sm"
      style={{ textAlign: "center" }}
    >
      <div className="middd">
        <div className="lgimgsol">{image}</div>
        <h2 className="text-ternary-dark dark:text-ternary-light">{solusi}</h2>
        <p className="text-ternary-dark dark:text-ternary-light font-medium">
          {desc}
        </p>
      </div>
    </div>
  );
};

const Solusi = () => {
  return (
    <div className="container mx-auto" style={{ padding: "0 35px" }}>
      <div className="solusi-page">
        {/* <div className="batass"></div> */}
        {/* <p className="font-general-medium text-2xl sm:text-4xl text-ternary-dark dark:text-ternary-light">
          What We Do?
        </p> */}
        <div className="classone" style={{ alignSelf: "self-start" }}>
          <h3 className="h3testiii">Our Services</h3>
          <h1 className="h1testiii dark:text-ternary-light mb-4">What We</h1>
        </div>
        <div className="solusis">
          {solusiData.map((solusiData, index) => (
            <Slsi key={index} {...solusiData} />
          ))}
        </div>
        {/* <Counter/> */}
      </div>
    </div>
  );
};

export default Solusi;
