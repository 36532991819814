import AboutMeBio from "../components/about/AboutMeBio";
import AboutCounter from "../components/about/AboutCounter";
import AboutClients from "../components/about/AboutClients";
import { AboutMeProvider } from "../context/AboutMeContext";
import ContactDetails from "../components/contact/ContactDetails";
// import ContactForm from '../components/contact/ContactForm';
import { motion } from "framer-motion";
import TeamPage from "./team/TeamPage";
import Tabscard from "../components/about2/Tabscard";
import { Helmet } from 'react-helmet';
import ClientBrands from "../components/about/clients/ClientBrands";
// import Footer2 from "../components/footernew/Footer";

const About = () => {
  return (
    <div className="flex" style={{ flexDirection: "column" }}>
      <Helmet>
        <title>PrimeTime | Tentang Kami</title>
        <meta property="og:title" content="PrimeTime Digital" />
        <meta
          name="description"
          content="PrimeTime Digital - Menyediakan solusi digital terbaik untuk bisnis Anda."
        />
        <meta
          name="keywords"
          content="Tentang Primetime"
        />
        <meta name="author" content="PrimeTime Digital Malang" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <AboutMeProvider>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, delay: 1 }}
          exit={{ opacity: 0 }}
          className="container mx-auto"
          style={{ padding: "0 3rem", margin: "auto" }}
        >
          <AboutMeBio />
        </motion.div>

        {/** Counter without paddings */}
        {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, delay: 1 }}
        exit={{ opacity: 0 }}
        style={{ padding: '0 3rem', margin:'auto'}}
      >
        <AboutCounter />
      </motion.div> */}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, delay: 1 }}
          exit={{ opacity: 0 }}
          className="container mx-auto"
          style={{ padding: "0 3rem" }}
        >
          {/* <AboutClients /> */}
          <ClientBrands />
          <TeamPage />
        </motion.div>

        {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          ease: "easeInOut",
          duration: 0.5,
          delay: 0.1,
        }}
        className="container justify-center mx-auto flex flex-col-reverse lg:flex-row py-5 lg:py-10 lg:mt-10"
      > */}
        {/* <ContactForm /> */}
        {/* <ContactDetails /> */}
        {/* <Footer2 /> */}
        {/* </motion.div> */}
      </AboutMeProvider>
    </div>
  );
};

export default About;
