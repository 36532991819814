import React, { useState,useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import './Revieww.css';
import { IoIosArrowBack,IoIosArrowForward } from "react-icons/io";
import { FaQuoteLeft } from "react-icons/fa";
const reviews = [
  {
    name: 'Yudistira',
    review: 'Suka banget dengan pelayanan dan hasil2 nya... dan gak cuma socila media saja yang diurusin... sampe pembuatan iklan dan video profile juga gas... recom banget',
    rating: 5,
    imgpp: 'https://lh3.googleusercontent.com/a-/ALV-UjXRaNnNl59Jd8gCooSpoCEsmqk0QF23dcF1FAnxYIpgNaQ=w36-h36-p-rp-mo-br100',
  },
  {
    name: 'Saifudin',
    review: 'Mantab, tempat untuk pekerja kreatif dengan hasil yang luar biasa. Ada studio nya juga, alat lengkap dan gak kaleng-kaleng, layanan banyak. Good Job dan Sukses Untuk Primetime.',
    rating: 4,
    imgpp: 'https://lh3.googleusercontent.com/a-/ALV-UjUym17GXtQg9ZG_TvLnaTlmKCmqQwwnZioCx2jPg8i8Jw8=w36-h36-p-rp-mo-br100',
  },
  {
    name: 'Amelia',
    review: 'masnya ramah ramah dan baik. bisa ngatur pose dan gaya juga biar ndak kaku. top banget pokoknya!',
    rating: 4.5,
    imgpp: 'https://lh3.googleusercontent.com/a-/ALV-UjWs63n1YIl7hdCy0nC6NqE3jg30GlEEO3UWgWXA9HfVLZ4=w36-h36-p-rp-mo-br100',
  },
  {
    name: 'Yasmine',
    review: 'Lokasi nya strategis banget ditambah lagi kantornya bersih, adem dan rapih jadi bikin nyaman😍',
    rating: 4,
    imgpp: 'https://lh3.googleusercontent.com/a/ACg8ocJLReFSkPhW6OIXUj8c0LJjL0-NWWN3vVzt8wUtUOvTDQ=w36-h36-p-rp-mo-br100',
  },
  {
    name: 'Yasmine',
    review: 'Lokasi nya strategis banget ditambah lagi kantornya bersih, adem dan rapih jadi bikin nyaman😍',
    rating: 4,
    imgpp: 'https://lh3.googleusercontent.com/a/ACg8ocJLReFSkPhW6OIXUj8c0LJjL0-NWWN3vVzt8wUtUOvTDQ=w36-h36-p-rp-mo-br100',
  },
];

const ReviewCarousel = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, skipSnaps: false }, [Autoplay({ delay: 11500 })]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (!emblaApi) return;
    const onSelect = () => {
      setSelectedIndex(emblaApi.selectedScrollSnap());
    };
    emblaApi.on('select', onSelect);
    return () => emblaApi.off('select', onSelect);
  }, [emblaApi]);

  const scrollTo = (index) => {
    if (emblaApi) {
      emblaApi.scrollTo(index);
    }
  };

  const handlePrev = () => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  };

  const handleNext = () => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  };

  return (
    <div className="outertestii flex" style={{padding:'0 35px', flexDirection: 'column',width: '100%', minHeight: '70dvh',justifyContent: 'center'}}>
      <h3 className='h3testii'>TESTIMONIALS</h3>
      <h1 className='h1testii dark:text-ternary-light mb-4'>What Clients</h1>
    <div className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {reviews.map((review, index) => (
            <div key={index} className="embla__slide">
              <div className="review-card flex bg-secondary-light dark:bg-ternary-dark dark:border-secondary-dark">
                <div className="lenrev1 flex items-center" style={{ justifyContent: 'space-between' }}>
                <FaQuoteLeft className='dark:text-ternary-light' color='var(--palett1)' size={32}/>
                
                <div className="review-card__rating">
                  
                  {Array.from({ length: Math.round(review.rating) }, (_, i) => (
                    <span key={i}>⭐</span>
                  ))}
                  {Array.from({ length: 5 - Math.round(review.rating) }, (_, i) => (
                    <span key={i + Math.round(review.rating)}>☆</span>
                  ))}
                </div>
                </div>
                <p className="review-card__review text-ternary-dark dark:text-ternary-light">{review.review}</p>
                <div className="revpeople flex" style={{ alignItems: 'center' }}>
                <img src={review.imgpp} alt="profile pic" style={{ width: '50px',marginRight: '15px' }} />
                <p className="review-card__name dark:text-ternary-light">{review.name}</p>

                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="embla__controls">
        <button className="embla__prev" onClick={handlePrev}>
          <IoIosArrowBack className='dark:text-ternary-light' color='var(--palett3)'/>
        </button>
        <button className="embla__next" onClick={handleNext}>
          <IoIosArrowForward className='dark:text-ternary-light' color='var(--palett3)'/>
        </button>
      </div>
    </div>
    </div>
  );
};

export default ReviewCarousel;
