import React, { useState } from 'react';
import './Tabscard.css';

const Tabscard = () => {
  const [activeTab, setActiveTab] = useState('visi'); // Set tab pertama sebagai default

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="tabscard-container">
      <div className="tabscard-nav">
        <div
          className={`tabscard text-ternary-dark dark:text-ternary-light ${activeTab === 'visi' ? 'active' : ''}`}
          onClick={() => handleTabClick('visi')}
        >
          <h1>VISI</h1>
        </div>
        <div
          className={`tabscard text-ternary-dark dark:text-ternary-light ${activeTab === 'misi' ? 'active' : '' }`}
          onClick={() => handleTabClick('misi')}
        >
          <h1>MISI</h1>
        </div>
      </div>

      <div className="tabscard-content">
        {activeTab === 'visi' && (
          <p className='text-ternary-dark dark:text-ternary-light' style={{ fontSize: '18px' }}>
            Menjadi pusat inovasi dalam industri produksi, menggabungkan teknologi canggih dengan ide-ide brilian untuk menciptakan karya-karya yang menginspirasi.
          </p>
        )}
        {activeTab === 'misi' && (
          <p className='text-ternary-dark dark:text-ternary-light' style={{ fontSize: '18px' }}>
            Menyediakan solusi digital terpadu yang mendukung klien dalam menghadapi tantangan transformasi digital. Kami akan terus mengembangkan ide-ide kreatif yang luar biasa untuk setiap proyek klien, karena kami yakin bahwa kesuksesan klien adalah kesuksesan kita.
          </p>
        )}
      </div>
    </div>
  );
};

export default Tabscard;