import imgs from "../images/news/berita1.webp";


const Blogs = [
    {
        id: 0,
        imageMain: imgs,
        imageSec: imgs,
        altImg: "primetime",
        altImgSec: "primetime",
        judul: "1 IHSG Bersiap Tembus 7.000, Banyak Momen Penting Dividen Interim",
        judulSEO: "1 IHSG Bersiap Tembus 7.000, Banyak Momen Penting Dividen Interim",
        descMeta: "Indeks harga saham gabungan (IHSG) dalam sepekan ke depan berpeluang menembus level psikologis 7.000.",
        isi: "isi berita",
        tag: ["tag"],
        date: "20 November 2023",
        kategori: "Bisnis",
        penulis: "Putri Setya Diningrum",
        scImg: "primetimedigital.id",
        scImgSec: "manaaja",
        avatarp: "https://malang.fun/wp-content/litespeed/avatar/dcd725b13ece8bbac86e295ab844e77b.jpg?ver=1700628549",
    },
    {
        id: 1,
        imageMain: "https://akcdn.detik.net.id/community/media/visual/2023/11/24/prabowo-subianto-di-umsurabaya-1_169.jpeg?w=700&q=90",
        imageSec: imgs,
        altImg: "primetime",
        altImgSec: "primetime",
        scImg: "primetime",
        scImgSec: "",
        judul: "2 IHSG Bersiap Tembus 7.000, Banyak Momen Penting Dividen Interim",
        judulSEO: "2 IHSG Bersiap Tembus 7.000, Banyak Momen Penting Dividen Interim",
        descMeta: "Indeks harga saham gabungan (IHSG) dalam sepekan ke depan berpeluang menembus level psikologis 7.000.",
        isi: [
            "Malang, Detikcom - Calon presiden Prabowo Subianto mengatakan bahwa pajak pendidikan harus ditekan serendah-rendahnya, bahkan jika memungkinkan dihapuskan. Hal ini disampaikan Prabowo dalam sesi tanya jawab Dialog Terbuka Muhammadiyah Bersama Calon Pemimpin Nasional di Universitas Muhammadiyah Malang, Jawa Timur, Jumat (24/11/2023).",
            '"Pajak untuk pendidikan harus serendah-rendahnya," kata Prabowo. a',
            "Prabowo menjelaskan bahwa pendidikan merupakan investasi jangka panjang yang sangat penting bagi bangsa. Oleh karena itu, ia ingin memastikan bahwa setiap orang memiliki akses yang sama terhadap pendidikan berkualitas, terlepas dari kondisi ekonomi mereka.",
            "Prabowo menjelaskan bahwa pendidikan merupakan investasi jangka panjang yang sangat penting bagi bangsa. Oleh karena itu, ia ingin memastikan bahwa setiap orang memiliki akses yang sama terhadap pendidikan berkualitas, terlepas dari kondisi ekonomi mereka.",
            "Prabowo menjelaskan bahwa pendidikan merupakan investasi jangka panjang yang sangat penting bagi bangsa. Oleh karena itu, ia ingin memastikan bahwa setiap orang memiliki akses yang sama terhadap pendidikan berkualitas, terlepas dari kondisi ekonomi mereka.",
        ],
        tag: ["tag","prabowo", "primetime"],
        date: "Jumat, 24 November 2023",
        kategori: "Bisnis",
        penulis: "Putri Setya Diningrum",
        avatarp: "https://malang.fun/wp-content/litespeed/avatar/dcd725b13ece8bbac86e295ab844e77b.jpg",
    },
    {
        id: 2,
        imageMain: imgs,
        imageSec: imgs,
        altImg: "primetime",
        altImgSec: "primetime",
        scImg: "",
        scImgSec: "",
        judul: "2 IHSG Bersiap Tembus 7.000, Banyak Momen Penting Dividen Interim",
        judulSEO: "2 IHSG Bersiap Tembus 7.000, Banyak Momen Penting Dividen Interim",
        descMeta: "Indeks harga saham gabungan (IHSG) dalam sepekan ke depan berpeluang menembus level psikologis 7.000.",
        isi: "isi berita",
        tag: ["tag"],
        date: "20 November 2023",
        kategori: "Bisnis",
        penulis: "Putri Setya Diningrum",
        avatarp: "https://malang.fun/wp-content/litespeed/avatar/dcd725b13ece8bbac86e295ab844e77b.jpg?ver=1700628549",
    },
    
];

export { Blogs };