import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db, auth } from './firebase';
import { Navigate, useNavigate } from 'react-router-dom';
import './News.css';

const News = () => {
  const [currentUser, setCurrentUser] = useState(true);
  const [judul, setJudul] = useState('');
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty()); // State for editor content
  const [frasaKunci, setFrasaKunci] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [tagInput, setTagInput] = useState('');
  const [tags, setTags] = useState([]);
  const [image, setImage] = useState(''); 
  const [altimg, setAltimg] = useState(''); 
  const [descimg, setDescimg] = useState(''); 
  const [judulSEO, setJudulSEO] = useState(''); 
  const [beritaList, setBeritaList] = useState([]);

  const addBerita = async (e) => {
    e.preventDefault();

    // Get the raw editor content
    const editorContent = convertToRaw(editorState.getCurrentContent());

    try {
      const docRef = await addDoc(collection(db, 'berita'), {
        judul: judul,
        konten: JSON.stringify(editorContent), // Save editor content as JSON string
        frasaKunci: frasaKunci,
        metaDescription: metaDescription,
        tag: tags,
        image: image, 
        altimg: altimg,
        descimg: descimg,
        judulSEO: judulSEO, 
        userId: currentUser.uid,
      });
      console.log('Document written with ID: ', docRef.id);
      
      setJudul('');
      setEditorState(EditorState.createEmpty()); // Clear editor content
      setFrasaKunci('');
      setMetaDescription('');
      setTags([]);
      setImage('');
      setAltimg('');
      setDescimg('');
      setJudulSEO('');
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && tagInput.trim() !== '') {
      setTags([...tags, tagInput.trim()]);
      setTagInput('');
    }
  };

  const fetchBerita = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'berita'));
      const newData = querySnapshot.docs
        .filter(doc => doc.data().userId === currentUser.uid)
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setBeritaList(newData);
      console.log(beritaList, newData);
    } catch (error) {
      console.error('Error fetching documents: ', error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        fetchBerita();
      } else {
        setBeritaList([]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <section className="news-container" style={{ display: 'grid', placeItems: 'center', width: '100%', minHeight: '100dvh' }}>
      <div className="news">
        <h1 className="header">News App</h1>

        <div>
          <div>
            <input type="text" placeholder="Judul" value={judul} onChange={(e) => setJudul(e.target.value)} />
          </div>

          <div>
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              placeholder="Konten"
            />
          </div>

          <div>
            <input type="text" placeholder="Frasa Kunci" value={frasaKunci} onChange={(e) => setFrasaKunci(e.target.value)} />
          </div>

          <div>
            <textarea placeholder="Meta Description SEO" value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} />
          </div>

          <div>
            {tags.map((tag, index) => (
              <span key={index} className="tag">{tag}</span>
            ))}
            <input 
              type="text" 
              placeholder="Tag" 
              value={tagInput} 
              onChange={(e) => setTagInput(e.target.value)} 
              onKeyDown={handleKeyDown} 
            />
          </div>

          <div>
            <input type="text" placeholder="Image URL" value={image} onChange={(e) => setImage(e.target.value)} />
            <input type="text" placeholder="alt img" value={altimg} onChange={(e) => setAltimg(e.target.value)} />
            <input type="text" placeholder="desc img" value={descimg} onChange={(e) => setDescimg(e.target.value)} />
          </div>

          <div>
            <input type="text" placeholder="Judul SEO" value={judulSEO} onChange={(e) => setJudulSEO(e.target.value)} />
          </div>

          <div className="btn-container">
            <button type="submit" className="btn" onClick={addBerita}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;
