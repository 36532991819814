import React from "react";
import './CardTeam.css';
import team1 from '../../../images/team/resize/1.jpg'
import team2 from '../../../images/team/resize/22.webp'
const CardTeam = () => {
  return (
    <div className="card-team-container">
    <div className="myCard">
      <div className="innerCard">
        <div className="frontSide">
          <img src={team1} alt="team1" />
        </div>
        <div className="backSide">
          <img src={team2} alt="team2" />
        </div>
      </div>
    </div>
    </div>
  );
};

export default CardTeam;
