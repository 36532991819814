import profileImage from "../../images/Primetime-light2.webp";
import { useContext } from "react";
import AboutMeContext from "../../context/AboutMeContext";
import Tabscard from "../about2/Tabscard";
import { Fade } from "react-awesome-reveal";
const AboutMeBio = () => {
  const { aboutMe } = useContext(AboutMeContext);

  return (
    <div className="outerrrbrandss" style={{ minHeight: "100dvh" }}>
      <div className="block sm:flex sm:gap-10 mt-10 sm:mt-20">
        <div className="w-full sm:w-1/4 mb-7 sm:mb-0">
          <Fade>
            <img src={profileImage} className="rounded-lg w-96" alt="" />
          </Fade>
        </div>
        {/* <Slide direction="left"> */}
        <div className="font-general-regular w-full sm:w-3/4 text-left">
          {aboutMe.map((bio) => (
            <p
              className="mb-2 text-ternary-dark dark:text-ternary-light text-lg text-left"
              key={bio.id}
            >
              {bio.bio}
            </p>
          ))}
          <Tabscard />
          {/* <h3 className='font-general-medium text-lg md:text-xl text-ternary-dark dark:text-ternary-light mt-2' style={{ fontWeight: 'bolder' }}>Visi:</h3>
				<p className="text-ternary-dark dark:text-ternary-light text-lg text-left">menjadi pusat inovasi dalam industri produksi, menggabungkan teknologi canggih dengan ide-ide brilian untuk menciptakan karya-karya yang menginspirasi.</p>
						
				<h5 className='font-general-medium text-lg md:text-xl text-ternary-dark dark:text-ternary-light mt-2'style={{ fontWeight: 'bolder' }}>Misi:</h5>
				<p className="text-ternary-dark dark:text-ternary-light text-lg text-left">menyediakan solusi digital terpadu yang mendukung klien dalam menghadapi tantangan transformasi digital. Kami akan terus mengembangkan ide-ide kreatif yang luar biasa untuk setiap proyek klien, karena kami yakin bahwa kesuksesan klien adalah kesuksesan kita.</p> */}
        </div>
        {/* </Slide> */}
      </div>
    </div>
  );
};

export default AboutMeBio;
