// src/components/TeamPage.jsx
// import React from "react";
import React, { useState } from "react";
import "./TeamPage.css";
// import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { teamMembers, teamMembersIntern } from "../../data/team-member";
// import Clients from "../../components/about/review/Client";
const TeamMember = ({ image, image2, name, position, instagram, linkedin }) => {
  return (
    <div className="card-team-container">
      <div className="myCard">
        <div className="innerCard dark:bg-ternary-dark">
          <div className="frontSide">
            <img src={image} alt="team1" />
          </div>
          <div className="backSide">
            <img src={image2} alt="team2" />
          </div>
        </div>
      </div>
    </div>
  );
};

const TeamPage = () => {
  const [activeTab, setActiveTab] = useState("main");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderTeamMembers = () => {
    const teamData = activeTab === "main" ? teamMembers : teamMembersIntern;
    return teamData.map((member, index) => (
      <TeamMember key={index} {...member} />
    ));
  };

  return (
    <div className="team-page">
      <h1 className="h1team dark:text-ternary-light">Introduce Our&nbsp;</h1>
      {/* <h1 className="text-ternary-light dark:text-ternary-light" style={{ fontWeight: "bold", marginBottom: '1.5rem' }}>Introduce Our Team</h1> */}
      <div className="tabs font-general-medium text-lg md:text-xl text-ternary-dark dark:text-ternary-light">
        <button
          className={`tab-button ${activeTab === "main" ? "active" : ""}`}
          onClick={() => handleTabChange("main")}
        >
          <h2 className="text-ternary-light dark:text-ternary-light">Staff</h2>
          {/* <h1 className="font-general-medium text-lg md:text-xl text-ternary-dark dark:text-ternary-light">Main Team</h1> */}
        </button>
        <button
          className={`tab-button ${activeTab === "intern" ? "active" : ""}`}
          onClick={() => handleTabChange("intern")}
        >
          <h2 className="text-ternary-light dark:text-ternary-light">Intern</h2>
          {/* <h1 className="font-general-medium text-lg md:text-xl text-ternary-dark dark:text-ternary-light">Intern Team</h1> */}
        </button>
      </div>
      <div
        className="team-members flex flex-wrap justify-center"
        style={{ minHeight: "100dvh" }}
      >
        {renderTeamMembers()}
      </div>
    </div>
  );
};

export default TeamPage;
