import React from "react";
import "./Footer.css";
import {
  FiPhone,
  FiMapPin,
  FiMail,
  FiInstagram,
  FiGlobe,
  FiYoutube,
} from "react-icons/fi";
import AppFooterCopyright from "../shared/AppFooterCopyright";
import MlgFun from "../../images/prime/malangfun.webp";
import Skr from "../../images/sukarev/9a.png";
import { Link } from "react-router-dom";
import { FaTiktok } from "react-icons/fa";
// import imglg from '../../images/Primetime-light2.webp';

const socialLinks = [
  {
    id: 1,
    icon: <FaTiktok />,
    url: "https://www.tiktok.com/@primetimedigital?is_from_webapp=1&sender_device=pc",
  },
  {
    id: 2,
    icon: <FiInstagram />,
    url: "https://www.instagram.com/primetime.idn/",
  },
  {
    id: 3,
    icon: <FiYoutube />,
    url: "https://www.youtube.com/channel/UCE5abOClQLweoPM_-njZhDw",
  },
  {
    id: 4,
    icon: <FiGlobe />,
    url: "https://www.malang.fun/",
  },
];
const Footer2 = () => {
  return (
    <div className="puter dark:bg-ternary-dark">
      <div className="firstfoot">
        <div className="kol1">
          {/* <img src={imglg} alt="logo primetime" style={{ width: '90px' }}/> */}
          <h1>Primetime Digital</h1>
          <h5>PT Hiend Digital Media</h5>
          <div className="isikol m-2">
            <p className="fdetail">
              <FiMapPin fontSize={25} /> Jl. Candi Panggung Bar. 66 Kav E-1,
              Mojolangu, Kec. Lowokwaru, Kota Malang, Jawa Timur 65142
            </p>
            <p className="fdetail" style={{ alignItems: "center" }}>
              <FiMail /> primetime.digi@gmail.com
            </p>
            <p className="fdetail" style={{ alignItems: "center" }}>
              <FiPhone /><a href="https://wa.me/628812323888" target="__blank">+62-881-2323-888</a>
            </p>
          </div>
        </div>
        <div className="secfot">
          <div className="kol2">
            <h1>Company</h1>
            <ul>
              <li>
                <Link to="/about">Who Are We?</Link>
              </li>
              <li>
                <Link to="/service">Our Services</Link>
              </li>
              <li>
                <Link to="/service">Our Partners</Link>
              </li>
            </ul>
          </div>

          <div className="kol3">
            <h1>Service</h1>
            <ul>
              <li>
                {/* <a href="/service">Compro</a> */}
                <Link to="/service">Compro</Link>
              </li>
              <li>SMM</li>
              <li>Marketing & Branding</li>
              <li>Iklan TVC</li>
              <li>Website Development</li>
              <li>
                <a href="/service">Selengkapnya</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="kol4">
          <div className="sociall">
            <h1>Social</h1>
            <ul className="flex gap-2 sm:gap-4 mt-2">
              {socialLinks.map((link) => (
                <a
                  href={link.url}
                  target="__blank"
                  key={link.id}
                  className="sosiall"
                >
                  <i className="text-xl sm:text-2xl md:text-3xl">{link.icon}</i>
                </a>
              ))}
            </ul>
          </div>
          <div className="partofus">
            <h1>Part Of Us</h1>
            <div className="flex gap-4 sm:gap-2" style={{ margin: "15px 0" }}>
            <a
              href="https://malang.fun"
              target="__blank"
            ><img src={MlgFun} alt="malngfun" width={55} /> </a>
              <a
              href="https://www.youtube.com/channel/UCfNDMUVOT_QtTGxyhkMJiTQ"
              target="__blank"
            ><img src={Skr} alt="skr" width={50} /></a>
            </div>
          </div>
        </div>
      </div>
      <AppFooterCopyright />
    </div>
  );
};

export default Footer2;
