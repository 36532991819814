import React, { useState } from "react";
import { FaHammer, FaBrush } from "react-icons/fa";
import "./CardService.css";
import { FaCameraRetro } from "react-icons/fa";
import { Helmet } from "react-helmet";

const CardServices = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [services, setServices] = useState([
    {
      id: 1,
      clas: "bounce-in-top",
      icon: <FaCameraRetro />,
      heading: "Compro",
      linkvid: "https://www.youtube.com/embed/b2l4zTS8lPg?si=RpOhA0NS9xHALkgB",
      description:
        "Tampilkan identitas bisnis Anda dengan desain kreatif yang memukau, menceritakan perjalanan perusahaan dengan visi yang jelas.",
    },
    {
      id: 2,
      clas: "bounce-in-top",
      icon: <FaCameraRetro />,
      heading: "SMM",
      linkvid: "https://www.youtube.com/embed/b2l4zTS8lPg?si=RpOhA0NS9xHALkgB",
      description:
        "Bangun kehadiran digital yang unik melalui strategi media sosial terpadu, meningkatkan keterlibatan, dan menciptakan pengalaman interaktif bagi audiens Anda.",
    },
    {
      id: 3,
      clas: "bounce-in-top",
      icon: <FaCameraRetro />,
      heading: "Marketing & Branding",
      linkvid: "https://www.youtube.com/embed/b2l4zTS8lPg?si=RpOhA0NS9xHALkgB",
      description:
        "Bentuk citra merek yang tak terlupakan dengan strategi pemasaran berbasis cerita dan desain visual yang memikat, membedakan bisnis Anda dari yang lain.",
    },
    {
      id: 4,
      clas: "bounce-in-top",
      icon: <FaCameraRetro />,
      heading: "Iklan TVC",
      linkvid: "https://www.youtube.com/embed/b2l4zTS8lPg?si=RpOhA0NS9xHALkgB",
      description:
        "Sampaikan pesan Anda secara dramatis dan efektif melalui iklan televisi yang memukau, menciptakan dampak yang mendalam pada pemirsa.",
    },
    {
      id: 5,
      clas: "bounce-in-top",
      icon: <FaCameraRetro />,
      heading: "Website Development",
      linkvid: "https://www.youtube.com/embed/b2l4zTS8lPg?si=RpOhA0NS9xHALkgB",
      description:
        "Bangun kehadiran online yang kuat dengan situs web yang responsif dan inovatif, menciptakan pengalaman digital yang memikat untuk pengunjung Anda.",
    },
  ]);

  return (
    <div className="outers flex items-center">
      <Helmet>
        <title>PrimeTime | Layanan</title>
        <meta property="og:title" content="PrimeTime Digital" />
        <meta
          name="description"
          content="PrimeTime Digital - Menyediakan solusi digital terbaik untuk bisnis Anda."
        />
        <meta name="keywords" content="Layanan" />
        <meta name="author" content="PrimeTime Digital Malang" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      {/* <h1 className='bounce-in-top font-general-medium text-2xl sm:text-4xl text-ternary-light dark:text-ternary-light' style={{ fontWeight: 'bold', margin: '2.5rem' }}>What We Do</h1> */}
      <h1 className="h1serv dark:text-ternary-light mb-8">What We&nbsp;</h1>
      <div className="row-service" style={{ width: "80%" }}>
        {services.map((service) => (
          <div key={service.id} className="column-service">
            <div className="card-service dark:bg-ternary-dark">
              <div className="icon-wrapper-card" onClick={openModal}>
                {service.icon}
              </div>
              <div className="icon-wrapper-card2">{service.icon}</div>
              <h3>{service.heading}</h3>
              <p>{service.description}</p>
            </div>
            {isModalOpen && (
              <div className="video-modal" style={{ zIndex: "99999999999999" }}>
                <iframe
                  width="560"
                  height="315"
                  // src="https://www.youtube.com/embed/b2l4zTS8lPg?si=RpOhA0NS9xHALkgB"
                  src={service.linkvid}
                  title="YouTube video player"
                  frameBorder="0"
                  allowFullScreen
                  autoPlay
                ></iframe>
                <button className="close-button" onClick={closeModal}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-square-rounded-x"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 10l4 4m0 -4l-4 4" />
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                  </svg>
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardServices;
