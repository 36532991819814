import React, { useEffect, useRef, useState } from "react";
import lottie from "lottie-web";
import "./BackVid.css";
import videoSource from "../../images/animation-img/primetime-landscape.webm";
import videoSource2 from "../../images/animation-img/primetime-potrait.webm";
import animationData from "../../images/animation-img/system-solid-12-arrow-down.json";
import { AiOutlineVideoCamera } from "react-icons/ai";
const SkeletonLoading = () => {
  return (
    <div
      className="skeleton-loading"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div className="shimmer bgsimlg dark:bg-ternary-dark max-sm:w-full max-sm:m-2">
      <AiOutlineVideoCamera size={42} />
      </div>
    </div>
  );
};

const BackVid = () => {
  const [isLoading, setIsLoading] = useState(true);
  const animationContainer = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const loadVideo = async () => {
      if (!isLoading) {
        lottie.loadAnimation({
          container: animationContainer.current,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: animationData,
        });
      }
    };

    loadVideo();
  }, [isLoading]); // Menggunakan isLoading sebagai dependensi untuk useEffect ini

  return (
    <div className="background-video">
      {isLoading ? (
        <SkeletonLoading />
      ) : (
        <div className="innervi w-full">
          {window.innerWidth <= 768 ? (
            <video autoPlay loop muted>
              <source src={videoSource2} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <video autoPlay loop muted>
              <source src={videoSource} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          )}
          <div
            className="opverlayy w-full top-0"
            style={{
              background:
                "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5))",
              height: "100dvh",
              position: "absolute",
            }}
          >
            <div
              className="animation-container"
              ref={animationContainer}
              style={{ opacity: "0.7", width: "40px", height: "40px" }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BackVid;
