// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import{getAuth} from "firebase/auth";

// const firebaseConfig = {
//     apiKey: "AIzaSyA8VDZN03RT3DfhxixPK83edzLW-JyEEL0",
//     authDomain: "primelog-532c9.firebaseapp.com",
//     projectId: "primelog-532c9",
//     storageBucket: "primelog-532c9.appspot.com",
//     messagingSenderId: "566952686383",
//     appId: "1:566952686383:web:c2af07b9c8b65f980827da",
//     measurementId: "G-BQYEF9ZRS0"
// };

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// export const auth = getAuth(app);
// export default app;


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import 'firebase/firestore';
import{getAuth} from "firebase/auth";
// import{getFirestore} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAef-VJI5BXrjQvuxQNns-pyNGZk4LMqcQ",
  authDomain: "primetimedigi-4f997.firebaseapp.com",
  projectId: "primetimedigi-4f997",
  storageBucket: "primetimedigi-4f997.appspot.com",
  messagingSenderId: "685154297834",
  appId: "1:685154297834:web:4a432f44c030558b3d7493",
  measurementId: "G-SZML9FDG4K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
export const auth = getAuth(app);
// export const firestore = getFirestore(app);
export const db = getFirestore(app);
export { storage };
// export const firestore = getFirestore.firestore();
// export default app;