// teamData.js
import teamImgs from "../images/herob.png";
import PakEnd from "../images/team/resize/1.webp";
import PakEnd2 from "../images/team/resize/2.webp";
import PakEko from "../images/team/resize/23.webp";
import PakEko2 from "../images/team/resize/24.webp";
import MasEd from "../images/team/resize/3.webp";
import MasEd2 from "../images/team/resize/4.webp";
import MasFar from "../images/team/resize/7.webp";
import MasFar2 from "../images/team/resize/8.webp";
import iva from "../images/team/resize/5.webp";
import iva2 from "../images/team/resize/6.webp";
import wul from "../images/team/resize/25.webp";
import wul2 from "../images/team/resize/26.webp";
import citra from "../images/team/resize/31.webp";
import citra2 from "../images/team/resize/32.webp";
import hilmi from '../images/team/resize/17.webp';
import hilmi2 from '../images/team/resize/18.webp';
import alfian from '../images/team/resize/19.webp';
import alfian2 from '../images/team/resize/20.webp';
import dika from '../images/team/resize/13.webp';
import dika2 from '../images/team/resize/14.webp';
import udin from '../images/team/resize/11.webp';
import udin2 from '../images/team/resize/12.webp';
import excel from '../images/team/resize/15.webp';
import excel2 from '../images/team/resize/16.webp';
import sahis from '../images/team/resize/9.webp';
import sahis2 from '../images/team/resize/10.webp';
import radit from '../images/team/resize/21.webp';
import radit2 from '../images/team/resize/22.webp';
import tahmid from '../images/team/resize/33.webp';
import tahmid2 from '../images/team/resize/34.webp';
import putri from '../images/team/resize/27.webp';
import putri2 from '../images/team/resize/28.webp';

const teamMembers = [
    {
      image: PakEnd ,
      image2: PakEnd2 ,
      name: "Endrita Agung W",
      position: "CEO",
      instagram: "https://www.instagram.com/johndoe/",
      linkedin: "https://www.linkedin.com/in/johndoe/",
    },
    {
      image: MasEd,
      image2: MasEd2,
      name: "Edo Putra",
      position: "Visual Creator Manager",
      instagram: "https://www.instagram.com/janesmith/",
      linkedin: "https://www.linkedin.com/in/janesmith/",
    },
    {
      image: MasFar,
      image2: MasFar2,
      name: "M Farid",
      position: "Story Teller Photographer",
      instagram: "https://www.instagram.com/janesmith/",
      linkedin: "https://www.linkedin.com/in/janesmith/",
    },
    // {
    //   image: iva,
    //   image2: iva2,
    //   name: "Sativa Triska",
    //   position: "Admin",
    //   instagram: "https://www.instagram.com/janesmith/",
    //   linkedin: "https://www.linkedin.com/in/janesmith/",
    // },
    
    {
      image: citra,
      image2: citra2,
      name: "Citra",
      position: "-",
      instagram: "https://www.instagram.com/janesmith/",
      linkedin: "https://www.linkedin.com/in/janesmith/",
    },
    {
      image: putri,
      image2: putri2,
      name: "Putri Setya D",
      position: "SEO Spesialist",
      instagram: "https://www.instagram.com/janesmith/",
      linkedin: "https://www.linkedin.com/in/janesmith/",
    },
    {
      image: PakEko,
      image2: PakEko2,
      name: "Eko",
      position: "-",
      instagram: "https://www.instagram.com/janesmith/",
      linkedin: "https://www.linkedin.com/in/janesmith/",
    },
    {
      image: dika,
      image2: dika2,
      name: "Andika Bhanu",
      position: "Content Planner",
      instagram: "https://www.instagram.com/janesmith/",
      linkedin: "https://www.linkedin.com/in/janesmith/",
    },
    {
      image: alfian,
      image2: alfian2,
      name: "Alfian Luky A",
      position: "Video Editor",
      instagram: "https://www.instagram.com/janesmith/",
      linkedin: "https://www.linkedin.com/in/janesmith/",
    },
  ];
  
  const teamMembersIntern = [
    {
      image: hilmi,
      image2: hilmi2,
      name: "Hilmy Ibrahim",
      position: "Social Media Assistant",
      instagram: "https://www.instagram.com/janesmith/",
      linkedin: "https://www.linkedin.com/in/janesmith/",
    },
    {
      image: udin,
      image2: udin2,
      name: "Ahmad Saifudin",
      position: "Graphic Designer",
      instagram: "https://www.instagram.com/janesmith/",
      linkedin: "https://www.linkedin.com/in/janesmith/",
    },
    {
      image: radit,
      image2: radit2,
      name: "Raditya",
      position: "Junior Video Editor",
      instagram: "https://www.instagram.com/janesmith/",
      linkedin: "https://www.linkedin.com/in/janesmith/",
    },
    {
      image: sahis,
      image2: sahis2,
      name: "Sahistya Pratita",
      position: "Web Developer",
      instagram: "https://www.instagram.com/janesmith/",
      linkedin: "https://www.linkedin.com/in/janesmith/",
    },
    {
      image: tahmid,
      image2: tahmid2,
      name: "Rahmat Tahmid A",
      position: "Web Developer",
      instagram: "https://www.instagram.com/janesmith/",
      linkedin: "https://www.linkedin.com/in/janesmith/",
    },
    
  ];
  
  export { teamMembers, teamMembersIntern };
  