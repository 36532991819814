export const aboutMeData = [
	{
		id: 1,
		bio: 'Prime Time didirikan pada tahun 2023 di Kota Malang dan fokus sebagai perusahaan konsultan digital. Dalam kapasitasnya sebagai konsultan digital, Prime Time memiliki peran penting dalam mendukung pertumbuhan merek-merek bisnis. Layanan yang mencakup pengembangan situs web, desain grafis, pembuatan konten menarik, serta konsultasi manajemen merek seperti peningkatan kesadaran brand dan saran terkait iklan digital.',
	},
	// {
	// 	id: 2,
	// 	bio: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil vel illum asperiores dignissimos cumque quibusdam et fugiat voluptatem nobis suscipit explicabo, eaque consequatur nesciunt, fugit eligendi corporis laudantium adipisci soluta?',
	// },
];
