// BlogDetail.js
import React from "react";
import { useParams } from "react-router-dom";
import { Blogs } from "../../data/data-blog";
import './Detail.css'
import bann1 from '../../images/banner/flayer 2k23.png'
import bann2 from '../../images/banner/60x160.png'
const BlogDetail = () => {
  const { id } = useParams();
  const blogId = parseInt(id, 10); // Mengonversi id ke integer dengan basis 10
  const blog = Blogs.find((blog) => blog.id === blogId);

  if (!blog) {
    return <p>Blog not found</p>;
  }

  return (
    <div className="conDet flex gap-4">
      {/* leftside */}
      <div className="sideleftblogdet">
        {/* judul */}
        <h2 className="txtjudul font-general-medium text-2xl sm:text-4xl mb-4 mt-12 text-ternary-dark dark:text-ternary-light">
          {blog.judulSEO}
        </h2>
        {/* penulis */}
        <div className="penuliss flex">
          <img
            src={blog.avatarp}
            alt="penulisprimetime"
            style={{ objectFit: "cover", borderRadius: "50%" }}
          />
          <p className=" text-small dark:text-ternary-light">{blog.penulis}</p>
        </div>
        {/* tgl rilis */}
        <p className="imghedernews mb-4 mt-2">{blog.date}</p>
        <div className="imgndetail flex">
            <img src={blog.imageMain} alt={blog.altImg} />
            <p>{blog.scImg}</p>
        </div>

        <div className="isiartkel">
            <article>Primetime - {blog.isi[0]}</article>
            <article>{blog.isi[1]}</article>
            <article>{blog.isi[2]}</article>
            <article>{blog.isi[3]}</article>
            <article>{blog.isi[4]}</article>
            <h3>{blog.isi[2]}</h3>
        </div>
        <div className="tagberitaa dark:text-ternary-light">
            {blog.tag}
        </div>
      </div>

      {/* side right ads */}
      <div className="sidertblogdet">
        <div className="adsprime">
          <img src={bann1} alt="primetime banner" />
            <img src="https://malang.fun/wp-content/uploads/2023/09/wangsakarta.png.webp" alt="primetime-wangsakarta" />
            <img src="https://malang.fun/wp-content/uploads/2023/06/ezgif.com-gif-maker-88.webp" alt="primetime-pks" />
            {/* <img src="https://malang.fun/wp-content/uploads/2023/09/calyna-1536x1536.png.webp" alt="primetime-calyna" /> */}
            {/* <img src={bann2} alt="primetime banner" /> */}

        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
