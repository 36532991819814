import imgs from '../images/malang-fun.png'
import bg from '../images/LOGO RECOLOR 2.png'


import { MdOutlineVideoSettings } from "react-icons/md";
import { RiSeoLine } from "react-icons/ri";
import { IoLogoAngular } from "react-icons/io";
const solusiData = [
    {
        image: <IoLogoAngular size={31}/>,
        altImg: "Strategi Primetime",
        solusi: "Branding",
        desc: "Share relevant, engaging, and inspiration brand message",
        bgImg: bg,
    },
    {
        image: <RiSeoLine size={31}/>,
        altImg: "Strategi Primetime",
        solusi: "SEO Marketing",
        desc: "Let us help you level up your search engine",
        bgImg: bg,
    },
    {
        image: <MdOutlineVideoSettings size={31}/>,
        altImg: "Strategi Primetime",
        solusi: "Video Editor",
        desc: "Create engaging video content that will make your brand stand out",
        bgImg: bg,
    },
];

export {solusiData};