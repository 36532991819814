import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import { Navigate, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const Profil = () => {
  const { userId } = useParams();
  const [user, loading, error] = useAuthState(auth);
  const [profile, setProfile] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const userDoc = querySnapshot.docs.find(
          (doc) => doc.data().userId === userId
        );

        if (userDoc) {
          setProfile(userDoc.data());
        } else {
          console.error("User profile not found");
        }
      } catch (error) {
        console.error("Error fetching profile: ", error);
      }
    };

    if (user) {
      fetchProfile();
    }
  }, [userId, user]);

//   if (!user) {
//     return <Navigate to="/login" />;
//   }

  return (
    <div
      className="flex justify-center"
      style={{
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        alignItems: "center",
      }}
    >
      <h2>Profil Pengguna</h2>
      {/* <p>Nama Lengkap: {profile.namaLengkap}</p> */}
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {profile && (
        <div>
          <p>Nama Lengkap: {profile.namaLengkap}</p>
          <p>Email: {profile.email}</p>
          <p>UID: {profile.uid}</p>
          <p>Level Akun: {profile.level}</p>
          <p>Link Image Profil: {profile.image}</p>
        </div>
      )}
    </div>
  );
};

export default Profil;
