// import Clients from '../components/about/review/Client';
// import ProjectCarousel from "../components/carousel/ProjectCarousel";
// import ReviewCarousel from "../components/carousel/ReviewCarousel";
import ProjectCarousel from "../components/carousel/ProjectCarousel";
// import Porto1 from "../components/porto/Porto1";
import PortoParall from "../components/porto/PortoParall";
import ProjectsGrid from "../components/projects/ProjectsGrid";
import { ProjectsProvider } from "../context/ProjectsContext";
import { Helmet } from "react-helmet";

const Projects = () => {
  return (
    <div className="flex" style={{ flexDirection: "column" }}>
      <Helmet>
        <title>PrimeTime | Digital Solution</title>
        <meta property="og:title" content="PrimeTime Digital" />
        <meta
          name="description"
          content="PrimeTime Digital - Menyediakan solusi digital terbaik untuk bisnis Anda."
        />
        <meta
          name="keywords"
          content="PrimeTime Digital, Digital Agency, solusi digital, teknologi, marketing, malang"
        />
        <meta name="author" content="PrimeTime Digital Malang" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <PortoParall />
      <ProjectCarousel />
      <ProjectsProvider>
        <div className="container mx-auto" style={{ padding: "0 35px" }}>
          {/* <Porto1 /> */}
          <ProjectsGrid />
        </div>
        {/* <Clients /> */}
      </ProjectsProvider>
    </div>
  );
};

export default Projects;
