import React from "react";
import "./Kontak.css";
import { FiPhone, FiMapPin, FiMail } from "react-icons/fi";

const contacts = [
  {
    id: 1,
    name: "Jl. Candi Panggung Bar. 66 Kav E-1, Mojolangu, Kec. Lowokwaru.",
    // name: "Jl. Candi Panggung Bar. 66 Kav E-1, Mojolangu, Kec. Lowokwaru, Kota Malang, Jawa Timur 65142",
    icon: <FiMapPin />,
    mapIframe: (
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3951.6110977544517!2d112.61469507422588!3d-7.9356222790303805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7883908764dd8b%3A0xe43e7beb0013a3df!2sPrimetime%20Digital%20Agency!5e0!3m2!1sen!2sin!4v1698313247754!5m2!1sen!2sin"
        width="380"
        // width='auto'
        height="280"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    ),
  },
  {
    id: 2,
    name: "primetime.digi@gmail.com",
    icon: <FiMail />,
  },
  {
    id: 3,
    name: "0881 2727 888",
    icon: <FiPhone />,
  },
];

const Kontak = () => {
  return (
    <div className="conttak">
      <div className="outerkontak flex">
        <div className="boxsleft flex bg-secondary-light dark:bg-ternary-dark dark:text-ternary-light">
          <h3>Contact Us</h3>
          <h1 className="dark:text-ternary-light">Get In Touch With Us!</h1>
          <p>
            Jika Anda memiliki keluhan atau ingin bertanya, silahkan hubungi
            kami melalui kontak berikut
          </p>
          <div className="bosxkon flex">
            <div
              className="itm1 mr-4"
              style={{
                display: "grid",
                placeItems: "center",
                // padding: "0.5rem 1.1rem",
                // backgroundColor: "var(--palett3)",
                color: "var(--palett1)",
                borderRadius: "1px",
              }}
            >
              <FiMapPin size={25} />
            </div>
            <div className="itm22 flex-column">
              <h4>Our Location</h4>
              <p>
                Jl. Candi Panggung Bar. 66 Kav E-1, Mojolangu, Kec. Lowokwaru.
              </p>
            </div>
          </div>
          <div className="bosxkon flex">
            <div
              className="itm1 mr-4"
              style={{
                display: "grid",
                placeItems: "center",
                // padding: "0.5rem 1.1rem",
                // backgroundColor: "var(--palett3)",
                color: "var(--palett1)",
                borderRadius: "1px",
              }}
            >
              <FiMail size={25} />
            </div>
            <div className="itm22 flex-column">
              <h4>Mail</h4>
              <p>primetime.digi@gmail.com</p>
            </div>
          </div>
          <div className="bosxkon flex">
            <div
              className="itm1 mr-4"
              style={{
                display: "grid",
                placeItems: "center",
                // padding: "0.5rem 1.1rem",
                // backgroundColor: "var(--palett3)",
                color: "var(--palett1)",
                borderRadius: "1px",
              }}
            >
              <FiPhone size={25} />
            </div>
            <div className="itm22 flex-column">
              <h4>Phone</h4>
              <p>0881 2727 888 | <a href="https://wa.me/628812323888" target="__blank">+62 881 2323 888</a></p>
            </div>
          </div>
        </div>

        <div className="boxsright flex">
          {contacts.find((contact) => contact.mapIframe)?.mapIframe}
        </div>
      </div>
    </div>
  );
};

export default Kontak;
