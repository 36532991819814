import React from "react";
import "./Benefit.css";
import { FaCheckCircle } from "react-icons/fa";
import img1 from '../../images/prime/proknow.jpg'
import img2 from '../../images/prime/proknow3.jpg'
import img3 from '../../images/prime/proknow2.jpg'
import imgp from '../../images/porto/porto1-primetime.png'
import Slide from 'react-awesome-reveal';
const Benefit = () => {
  return (
    <div className="benefcont dark:bg-ternary-dark">
    <div className="contbenf">
      {/* left sidee */}
      <div className="benfside"> {/* ini formatnya kolom, jadi terdapat 2 gambar di 2 baris*/}
        <div className="sideimg">  {/* ini adalah row */}
        <Slide direction="down" ><img className="imgbt1" src={imgp} alt="about primetime" /> </Slide>
        <Slide direction="left"><img className="imgbtt2" src={img2} alt="about primetime" /> </Slide>
            {/* <img className="imgbtt2" src={img2} alt="benefit primetime" /> */}
        </div>
        <div className="sideimg2">
        <Slide direction="up"><img className="imgbt3" src={img1} alt="about primetime" /> </Slide>
        <Slide direction="right"><img className="imgbt4" src={img3} alt="about primetime" /> </Slide>
            {/* <img className="imgbt3" src={img1} alt="benefit primetime" /> */}
            {/* <img className="imgbt4" src={img3} alt="benefit primetime" /> */}
        </div>
      </div>
      {/* right side */}
      <div className="righttsideer">
        <div className="sideRitem">
          <h1 className="textpalet3 mb-2" style={{ fontWeight: 600 }}>CREATIVE</h1>
          <h2 className="teksbenefith2 font-general-medium text-2xl sm:text-4xl mb-2 text-ternary-dark dark:text-ternary-light">
            Interactivity Between team members is the key of Succes.
          </h2>
          <p className="teksbenefith2 font-small text-sm sm:text-xl mb-4 dark:text-ternary-light">
          Creative thinking is at the heart of everything. At Primetime, we are always looking for innovative solutions to every challenge. Join us and bring infinite creativity in every step.
          </p>
        </div>
        <div className="sideRitem2">
          <div className="benfitemmm1">
            <div className="kotakexx">
              <p className="fdetail dark:text-ternary-light" style={{ alignItems: "center" }}>
              <FaCheckCircle color="var(--palett3)"/>
                Inovasi
              </p>
            </div>
            <div className="kotakexx">
              <p className="fdetail dark:text-ternary-light" style={{ alignItems: "center" }}>
              <FaCheckCircle color="var(--palett2)"/>
                Kolaborasi
              </p>
            </div>
          </div>
          <div className="benfitemmm1">
          <div className="kotakexx">
              <p className="fdetail dark:text-ternary-light" style={{ alignItems: "center" }}>
              <FaCheckCircle color="var(--palett3)"/>
                24/7 Support
              </p>
            </div>
            <div className="kotakexx">
              <p className="fdetail dark:text-ternary-light" style={{ alignItems: "center" }}>
              <FaCheckCircle color="var(--palett2)"/>
                Live Modification
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
 </div>
  );
};

export default Benefit;
