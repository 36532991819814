// import { useState } from "react";
import { useState, useEffect } from "react";
import { FiMenu, FiMoon, FiSun, FiX } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import useThemeSwitcher from "../../hooks/useThemeSwitcher";
import HireMeModal from "../HireMeModal";
import logoLight from "../../images/Primetime-light2.webp";
import logoDark from "../../images/Primetime-light2.webp";
import { motion } from "framer-motion";
import Button from "../reusable/Button";
import './Header.css';
// import LanguageToggle from "../reusable/lang/Langg";

const AppHeader = () => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeTheme, setTheme] = useThemeSwitcher();
  const [scrolling, setScrolling] = useState(false);

  // function toggleMenu() {
  //   if (!showMenu) {
  //     setShowMenu(true);
  //     setScrolling(true);
  //   } else {
  //     setShowMenu(false);
  //     setScrolling(true);
  //   }
  // }
  function toggleMenu() {
    setShowMenu(!showMenu);
    setScrolling(!showMenu); // Jika menu dibuka, set scrolling ke true
  }

  function closeMenu() {
    setShowMenu(false);
    setScrolling(false);
  }

  function showHireMeModal() {
    if (!showModal) {
      document
        .getElementsByTagName("html")[0]
        .classList.add("overflow-y-hidden");
      setShowModal(true);
    } else {
      document
        .getElementsByTagName("html")[0]
        .classList.remove("overflow-y-hidden");
      setShowModal(false);
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
        setShowMenu(false);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isHomePage = location.pathname === "/";

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      id="nav"
      // className={`xl:container ${
      //   scrolling ? "scrolled bg-secondary-light dark:bg-ternary-dark" : ""

      // }`}
      className={`xl:container ${
        scrolling
          ? `scrolled bg-secondary-light dark:bg-ternary-dark text-ternary-dark ${
              isHomePage ? "text-white" : "text-primary-dark"
            }`
          : isHomePage
          ? "text-white"
          : "text-black"
      }`}
      style={{
        padding: scrolling ? "0px 9px" : "15px 21px",
        // margin: scrolling ? "0" : "0",
        width: "100%",
        margin: "0",
        position: "fixed",
        zIndex: "999999",
        // borderBottom: scrolling ? "1.5px solid #13121233" : "none",
        boxShadow: scrolling ? "0 2px 4px rgba(0,0,0,.1)" : "none",
        background: scrolling ? "transparent" : "none",
        backdropFilter: scrolling ? "blur(71.5px)" : "none",
        // padding: scrolling ? "0px 9px" : "15px 21px",
        // margin: scrolling ? "0" : "0",
        // width: "100%",
        // position: "fixed",
        // zIndex: "999999",
      }}
    >
      <div className="z-10 max-w-screen-2xl items-center sm:flex sm:justify-between sm:items-center">
        {/* Header menu links and small screen hamburger menu */}
        <div className="flex justify-between items-center px-4 sm:px-0">
          <div>
            <Link to="/">
              {activeTheme === "dark" ? (
                <img src={logoDark} className="w-36" alt="primetime digital" />
              ) : (
                <img src={logoLight} className="w-36" alt="primetime digital" />
              )}
            </Link>
          </div>

          {/* Theme switcher small screen */}
          <div
            onClick={() => setTheme(activeTheme)}
            aria-label="Theme Switcher"
            className="block sm:hidden ml-0 bg-primary-light dark:bg-ternary-dark p-3 shadow-sm rounded-xl cursor-pointer"
            style={{ zIndex: 11 }}
          >
            {activeTheme === "dark" ? (
              <FiMoon className="text-ternary-dark hover:text-gray-400 dark:text-ternary-light dark:hover:text-primary-light text-xl" />
            ) : (
              <FiSun className="text-gray-200 hover:text-gray-50 text-xl" />
            )}
          </div>

          {/* Small screen hamburger menu */}
          <div className="sm:hidden" style={{ zIndex: 11 }}>
            {/* <div className={scrolling ? "sm:hidden scrolled" : "sm:hidden"} style={{ zIndex: 11 }}> */}
            <button
              onClick={toggleMenu}
              type="button"
              className="focus:outline-none"
              aria-label="Hamburger Menu"
              style={{ zIndex: 11 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="h-7 w-7 fill-current text-secondary-dark dark:text-ternary-light"
                style={{ zIndex: 12 }}
              >
                {showMenu ? (
                  <FiX className="text-3xl" />
                ) : (
                  <FiMenu className="text-3xl" />
                )}
              </svg>
            </button>
          </div>
        </div>

        {/* Header links small screen */}
        <div
          className={
            showMenu
              ? "block m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none"
              : "hidden"
          }
        >
          <Link
            to="/"
            className="nav-link block text-left text-lg text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  sm:mx-4 mb-2 sm:py-2 pt-3 sm:pt-2"
            aria-label="Beranda"
            id="navv"
            onClick={closeMenu} 
          >
            Beranda
          </Link>
          <Link
            to="/about"
            className="block text-left text-lg text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
            aria-label="About Us"
            onClick={closeMenu} 
          >
            Tentang
          </Link>
          <Link
            to="service"
            className="block text-left text-lg text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
            aria-label="Projects"
            onClick={closeMenu} 
          >
            Layanan
          </Link>
          <Link
            to="/projects"
            className="block text-left text-lg text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
            aria-label="Projects"
            onClick={closeMenu} 
          >
            Projects
          </Link>
          <Link
            to="/kontak"
            className="block text-left text-lg dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
            aria-label="Projects"
            onClick={closeMenu} 
          >
            Kontak
          </Link>

          
          {/* <div className="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light dark:border-secondary-dark">
            <span
              onClick={showHireMeModal}
              className="font-general-medium sm:hidden block text-left text-md bg-indigo-500 hover:bg-indigo-600 text-white shadow-sm rounded-sm px-4 py-2 mt-2 duration-300 w-24"
              aria-label="Hire Me Button"
              style={{ backgroundColor: "var(--palett3)" }}
            >
              <Button title="Kontak" />
            </span>
          </div> */}
        </div>

        {/* Header links large screen */}
        {/* style={{ color: scrolling ? "black" : "white", }} */}
        <div className="font-general-medium hidden m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-between items-center shadow-lg sm:shadow-none">
          <Link
            to="/"
            className="nav-link block text-left text-lg dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-dark  sm:mx-4 mb-2 sm:py-2"
            aria-label="Projects"
          >
            Beranda
          </Link>
          <Link
            to="/about"
            className="nav-link block text-left text-lg dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-dark  sm:mx-4 mb-2 sm:py-2"
            aria-label="Tentang Kami"
            style={{ zIndex: 11 }}
          >
            Tentang
          </Link>
          <Link
            to="service"
            className="nav-link block text-left text-lg dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-dark  sm:mx-4 mb-2 sm:py-2"
            aria-label="Layanan"
          >
            Layanan
          </Link>
          <Link
            to="/projects"
            className="nav-link block text-left text-lg dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-dark  sm:mx-4 mb-2 sm:py-2"
            aria-label="About Us"
            style={{ zIndex: 11 }}
          >
            Project
          </Link>
          <Link
            to="/kontak"
            // to="/blog"
            className="nav-link block text-left text-lg dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-dark  sm:mx-4 mb-2 sm:py-2"
            aria-label="kontak"
            style={{ zIndex: 11 }}
          >
            Kontak
          </Link>

          {/* <Link
            to="/contact"
            className="block text-left text-lg text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  sm:mx-4 mb-2 sm:py-2"
            aria-label="Contact"
          >
            Contact
          </Link> */}
        </div>

        {/* Header right section buttons */}
        <div className="hidden sm:flex items-center flex-col md:flex-row">
          {/* <div class="button-2 flex items-center" style={{ padding:'9px 0', borderRadius: '5px' }}>
            <a href="https://wa.me/628812727888">
              <h6 className=" text-ternary-light">Contact</h6>
            </a>
          </div> */}
          {/* <div className="hidden md:flex">
            <span
              onClick={showHireMeModal}
              className="text-md font-general-medium bg-indigo-500 hover:bg-indigo-600 text-white shadow-sm rounded-md px-5 py-2.5 duration-300"
              aria-label="Hire Me Button"
              style={{ backgroundColor: "#FF8738", zIndex: 11 }}
            >
              <Button title="Contact" />
            </span>
          </div> */}
          {/* <LanguageToggle /> */}
          {/* Theme switcher large screen */}
          <div
            onClick={() => setTheme(activeTheme)}
            aria-label="Theme Switcher"
            className="ml-8 dark:bg-ternary-dark p-3 shadow-sm cursor-pointer"
            style={{
              zIndex: 11,
              backgroundColor: "var(--palett2)",
              color: "white",
              borderRadius: "5px",
            }}
          >
            {activeTheme === "dark" ? (
              <FiMoon
                className="text-ternary-light hover:text-gray-400 dark:text-ternary-light dark:hover:text-primary-light text-xl"
                style={{ zIndex: 11 }}
              />
            ) : (
              <FiSun
                className="text-ternary-light hover:text-gray-50 text-xl"
                style={{ zIndex: 11 }}
              />
            )}
          </div>
        </div>
      </div>
      {/* Hire me modal */}
      {/* <div>
        {showModal ? (
          <HireMeModal onClose={showHireMeModal} onRequest={showHireMeModal} />
        ) : null}
        {showModal ? showHireMeModal : null}
      </div> */}
    </motion.nav>
  );
};

export default AppHeader;
