import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { Link, Navigate } from "react-router-dom";
import { db, auth } from "../firebase";

const BeritaList = () => {
  const [beritaList, setBeritaList] = useState([]);
  const [currentUser, setCurrentUser] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        fetchBerita();
      } else {
        setBeritaList([]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const fetchBerita = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "berita"));
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setBeritaList(newData);
    } catch (error) {
      console.error("Error fetching documents: ", error);
    }
  };

  const handleDelete = async (beritaId) => {
    try {
      await deleteDoc(doc(db, "berita", beritaId));
      const querySnapshot = await getDocs(collection(db, "berita"));
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setBeritaList(newData);
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  
  return (
    <div className="bungkusss" style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
      
    <div className="bungkss" style={{ width: '90%', margin: '5px', fontFamily: 'Arial, sans-serif', overflowX: 'auto' , scrollbarColor: '#feefee #f2f3f4' }}>
      <h2 style={{ color: '#333', fontSize: '24px' }}>Seluruh Berita</h2>
      
      <table style={{ width: '70%', borderCollapse: 'collapse', marginTop: '10px'}}>
        <thead style={{ background: '#f2f2f2' }}>
          <tr>
            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Judul</th>
            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Konten</th>
            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Frasa Kunci</th>
            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Meta Description</th>
            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Tag</th>
            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Image</th>
            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Judul SEO</th>
            <th style={{ padding: '10px', border: '1px solid #ddd' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {beritaList.map((berita) => (
            <tr key={berita.id}>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{berita.judul}</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{berita.konten}</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{berita.frasaKunci}</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{berita.metaDescription}</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{berita.tag}</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{berita.image}</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>{berita.judulSEO}</td>
              <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                {/* Tambahkan tombol aksi di sini */}

                <button onClick={() => handleDelete(berita.id)} style={{ background: 'red', color: '#fff', padding: '5px 10px', border: 'none', cursor: 'pointer' }}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <Link to="/news" style={{ textDecoration: 'none' }}>
        <button style={{ background: '#007bff', color: '#fff', padding: '10px', border: 'none', borderRadius: '5px', marginBottom: '10px', cursor: 'pointer' }}>
          Tambah Berita
        </button>
      </Link>
    </div>
  );
};

export default BeritaList;
