import React, { useEffect, useRef } from "react";
import "./PortoParall.css";
import lottie from "lottie-web";
import animationData from "../../images/animation-img/system-solid-12-arrow-down.json";
const PortoParall = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }, []);
  return (
    <div className="outerportoff">
      <div className="portoppp1">
        <div className="cappor">
          <span className="borderr">SCROLL DOWN</span>
        </div>
        <div
          className="animation-container"
          ref={animationContainer}
          style={{ opacity: "0.7", width: "50px", height: "50px" }}
        ></div>
      </div>
      <div className="portoppp2">
        <div className="cappor">
          <span className="borderr">ALWAYS</span>
        </div>
      </div>
      <div className="portoppp3">
        <div className="cappor">
          <span className="borderr">THINKING</span>
        </div>
      </div>
      <div className="portoppp4">
        <div className="cappor">
          <span className="borderr">CREATIVE</span>
        </div>
      </div>
      {/* <div className="portoppp5">
        <div className="cappor">
          <span className="borderr">WITH CONTENT</span>
        </div>
      </div> */}
    </div>
  );
};

export default PortoParall;
