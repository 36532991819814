import useThemeSwitcher from '../../hooks/useThemeSwitcher';
import { FiArrowDownCircle } from 'react-icons/fi';
import developerLight from '../../images/team/teamhero.svg';
import developerDark from '../../images/team/teamhero.svg';
import { motion } from 'framer-motion';
import blur1 from '../../images/blob (3).svg'
import blur2 from '../../images/blob.svg'
import blur3 from '../../images/blobtop.svg'
const AppBanner = () => {
	const [activeTheme] = useThemeSwitcher();

	return (
		<motion.section
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ ease: 'easeInOut', duration: 0.9, delay: 0.2 }}
			className="flex flex-col sm:justify-between items-center sm:flex-row mt-12 md:mt-2"
			// style={{ height: '55vh' }}
			id='bekhero'
		>
			{/* <img className='batuhero1' src={blur1} alt="blurr" style={{filter: 'blur(39px)' ,width: '29%',position: 'absolute', left: '-5%', bottom: '-10%'}}/>
			<img className='batuhero' src={blur2} alt="blurr" style={{filter: 'blur(81px)' ,width: '45%',position: 'absolute', right: '0', top: '0', zIndex: '0'}}/> */}
			{/* <div className="bgbg" style={{ width: '65%', opacity: '0.5',height: '161px',backgroundColor: 'gray',position: 'absolute', right: 0,borderTopLeftRadius: '50px', borderBottomLeftRadius: '100px'}}></div> */}
			{/* <img className='batuhero' src={blur2} alt="blurr" style={{width: '30%',position: 'absolute', right: '9%', bottom: '-10%'}}/> */}
			<div className="w-full md:w-1/3 text-left" style={{ zIndex: 11 }}>
				<motion.h1
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						ease: 'easeInOut',
						duration: 0.9,
						delay: 0.1,
					}}
					className="font-general-semibold text-2xl lg:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase"
				>
					Boost Your Business
				</motion.h1>
				<motion.p
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						ease: 'easeInOut',
						duration: 0.9,
						delay: 0.2,
					}}
					className="font-general-medium mt-4 text-lg md:text-xl lg:text-2xl xl:text-3xl text-center sm:text-left leading-normal text-gray-500 dark:text-gray-200"
					style={{ color: '#FF8738',zIndex: 11 }}
				>
					Our hands, are ready to work for your brand.
				</motion.p>
				{/* <motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						ease: 'easeInOut',
						duration: 0.9,
						delay: 0.3,
					}}
					className="flex justify-center sm:block"
				>
					<a
						download="Stoman-Resume.pdf"
						href="/files/Stoman-Resume.pdf"
						className="font-general-medium flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-indigo-500 text-gray-500 hover:text-white duration-500"
						aria-label="Download Resume"
					>
						<FiArrowDownCircle className="mr-2 sm:mr-3 h-5 w-5 sn:w-6 sm:h-6 duration-100"></FiArrowDownCircle>
						<span className="text-sm sm:text-lg font-general-medium duration-100">
							Contact Us
						</span>
					</a>
				</motion.div> */}
			</div>
			<motion.div
				initial={{ opacity: 0, y: -180 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ ease: 'easeInOut', duration: 0.9, delay: 0.2 }}
				className="w-full sm:w-2/3 text-right float-right mt-8 sm:mt-0"
				style={{ zIndex: 10 }}
			>
				<img
					src={
						activeTheme === 'dark' ? developerLight : developerDark
					}
					alt="Developer"
				/>
			</motion.div>
		</motion.section>
	);
};

export default AppBanner;
