import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import './BeritaStyle.css';

const BeritaDetail = () => {
  const { beritaId } = useParams();
  const [beritaDetail, setBeritaDetail] = useState(null);

  useEffect(() => {
    const fetchBeritaDetail = async () => {
      try {
        const beritaDoc = await getDoc(doc(db, "berita", beritaId));
        if (beritaDoc.exists()) {
          setBeritaDetail({ id: beritaDoc.id, ...beritaDoc.data() });
        } else {
          console.error("Document not found");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    };

    fetchBeritaDetail();
  }, [beritaId]);

  return (
    <div className="berutama">
      {beritaDetail ? (
        <div className="outteerrmain flex">
          <div className="outerrleft flex-column">
            <h2>{beritaDetail.judul}</h2>
            <img
              src={beritaDetail.image}
              alt={beritaDetail.altimg}
              style={{
                maxWidth: "100%",
                maxHeight: "400px",
                objectFit: "cover",
                // borderRadius: "5px",
                // marginBottom: "20px",
              }}
            />
            <p>{beritaDetail.konten}</p>
          </div>
          <div className="outerrright">
            <img
              src="https://malang.fun/wp-content/uploads/2023/09/wangsakarta.png.webp"
              alt="primetime-wangsakarta"
            />
            <img
              src="https://malang.fun/wp-content/uploads/2023/06/ezgif.com-gif-maker-88.webp"
              alt="primetime-pks"
            />
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default BeritaDetail;
