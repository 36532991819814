import React from "react";
import brandsss from "../../../images/animation-img/clientall.png";
import "./ClientBrands.css";

const ClientBrands = () => {
  return (
    <div className="outerrrbrandss">
      <div className="outerrmainbrands flex">
        {/* <h1 className="bounce-in-right font-general-medium text-2xl sm:text-4xl text-ternary-dark dark:text-ternary-light" style={{ fontWeight: 'bold', marginBottom: '2.5rem' }}>Klien Kami</h1> */}
        <h1 className="h1clients dark:text-ternary-light mb-8">Our&nbsp;</h1>
        <img src={brandsss} alt="brands primetime" />
      </div>
    </div>
  );
};

export default ClientBrands;
