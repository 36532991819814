import React, { Component } from 'react';
// import logo from '../../images/Primetime-light2.png';
// import SplashLogo from './SplashLogo';
// import WhirlAnimation from './whirl-animation';
import './Splash.css'

function SplashMessage() {
    return (
        <div className='container-splash bg-secondary-dark'>
            {/* <SplashLogo/> */}
            {/* <WhirlAnimation/> */}
            {/* <img src={logo} className="App-logo" alt="logo" /> */}
            <div className="loaderrr"></div>
        </div>
    );
}

export default function withSplashScreen(WrappedComponent) {
    return class extends Component {
        constructor (props) {
            super(props);
            this.state = {
                loading: true,
            };
        }

        async componentDidMount() {
            try {
                // Put here your await requests/ API requests
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 2500)
            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false,
                });
            }
        }

        render() {
            // while checking user session, show "loading" message
            if (this.state.loading) return SplashMessage();

            // otherwise, show the desired route
            return <WrappedComponent {...this.props} />;
        }
    };
}
